<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <p class="title">Register Activity</p>

        <v-card>

          <v-card-title>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
                  :search="search"
                  :headers="tableHeaders"
                  :items="registerActivity"
                  :items-per-page="20"
          ></v-data-table>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { db } from '../plugins/firebase'

  export default {
    name: 'RegisterActivity',

    data: () => ({
      search: '',
      tableHeaders: [
        {
          text: 'Timestamp',
          align: 'start',
          value: 'timestamp',
        },
        { text: 'UTC', value: 'humanTimestamp' },
        { text: 'Address', value: 'addr' },
        { text: 'Direction', value: 'direction' },
        { text: 'Data', value: 'data' },
        { text: 'Interface', value: 'interface' },
      ],
      registerActivityObject: {},
      registerActivity: [],
      registerActivityListener: false,
    }),

    watch: {
      '$store.state.user': {
        immediate: true,
        handler(value) {

          if (!value) {
            if (this.registerActivityListener) {
              this.registerActivityListener()
            }
            this.registerActivity = []
          } else {

            this.registerActivityListener = db.collection("RegisterActivity")
                    .onSnapshot(
                            (snapshot) => {

                              snapshot.docChanges().forEach((change) => {

                                  if (change.type === "added" || change.type === "modified") {

                                    let data = change.doc.data()
                                    data.firestoreId = change.doc.id
                                    data.addr = '0x'+data.addr.toString(16)
                                    data.humanTimestamp = this.$moment.unix(data.timestamp, "DD MM YYYY hh:mm:ss", true).utc().format();
                                    this.registerActivityObject[change.doc.id] = data

                                  } else if (change.type === "removed") {

                                    delete this.registerActivityObject[change.doc.id]

                                  }

                                });

                                this.registerActivity = Object.entries(this.registerActivityObject).map(e => e[1])


                            },
                            (error) => {
                              console.error('RegisterActivity', error.message)
                              this.registerActivityObject = []
                            })
          }

        }
      }

    },

    beforeDestroy() {
      if (this.registerActivityListener) {
        this.registerActivityListener()
        this.registerActivityObject = []
      }
    }

  }
</script>
