<template>
<register-activity />
</template>

<script>
  import RegisterActivity from '../components/RegisterActivity'

  export default {
    name: 'Register',

    components: {
      RegisterActivity,
    },
  }
</script>
